<template>
  <div class="panel" v-loading="loading">
    <div class="panel-item">
      <header>Нууц үг сэргээх</header>
    </div>
    <div class="panel-item">
      <el-form
        class="login-form"
        :model="model"
        :rules="rules"
        @submit.native.prevent="onSubmit"
        ref="form"
      >
        <el-form-item prop="code" label="Баталгаажуулах код:">
          <el-input
            v-model="model.code"
            placeholder=""
            prefix-icon="fas fa-lock-open"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newpassword" label="Шинэ нууц үг:">
          <el-input
            v-model="model.newpassword"
            placeholder=""
            type="password"
            prefix-icon="fas fa-lock"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmNewPassword" label="Шинэ нууц үг давтах:">
          <el-input
            v-model="model.confirmNewPassword"
            type="password"
            placeholder=""
            prefix-icon="fas fa-lock"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button block"
            type="primary"
            native-type="submit"
            @click="onSubmit()"
            >Шинэчлэх</el-button
          >
        </el-form-item>
        <div class="text-center">
          <router-link class="inventory" to="/signIn">Нэвтрэх</router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { changePasswordSumbit } from '../../../utils/auth.js'
export default {
  name: 'changePassword',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.model.confirmNewPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.model.newpassword) {
        callback(new Error('Two inputs don\'t match!'))
      } else {
        callback()
      }
    }
    return {
      model: {
        code: '',
        newpassword: '',
        confirmNewPassword: ''
      },
      loading: false,
      rules: {
        code: [
          {
            required: true,
            message: 'Нэвтрэх нэр хоосон байна',
            trigger: 'blur'
          }
        ],
        newpassword: [
          { validator: validatePass, trigger: 'blur' },
          {
            min: 8,
            message: '8 тэмдэгтээс богино байна',
            trigger: 'blur'
          }
        ],
        confirmNewPassword: [
          { validator: validatePass2, trigger: 'blur' },
          {
            min: 8,
            message: '8 тэмдэгтээс богино байна',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          changePasswordSumbit({
            username: this.$route.params.username,
            code: this.model.code,
            new_password: this.model.newpassword
          }).then((response) => {
            this.loading = false
            this.$router.push({
              name: 'signIn'
            })
          }).catch(error => {
            this.loading = false
            this.$message.error(error)
          })
        } else {
          return null
        }
      })
    }
  }
}
</script>
